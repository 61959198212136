<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Anggota Keluarga</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-karyawan">Karyawan</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{
                        name: 'DetailEmployee',
                        params: { id: id_karyawan },
                      }"
                      >Detail Karyawan</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }} Anggota Keluarga
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="validated == 1 && labelPage == 'Edit'"
            >
              <button class="btn btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div
              v-show="
                (validated != 1 && labelPage == 'Edit') || labelPage == 'Tambah'
              "
            >
              <button class="btn btn-save mr-3" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
              <button
                class="btn btn-blue"
                type="button"
                v-if="enableSync"
                @click="onSync"
                :disabled="isSubmit"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan & Sync
              </button>
            </div>
          </div>
          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">{{ labelPage }} Anggota Keluarga</div>
                <div class="sub-title">
                  Informasi detail dari anggota keluarga
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nama :</label>
                  <input
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    type="text"
                    class="form-control align-self-center"
                    v-model="formData.nama"
                    @keyup="formChange('nama')"
                    :class="{
                      'is-invalid': formError && formError.nama,
                    }"
                    placeholder="Masukkan Nama"
                  />
                </div>

                <div class="form-group">
                  <label>Status Pernikahan :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.status_pernikahan"
                    :options="optionMaritalStatus"
                    placeholder="Pilih Status Pernikahan"
                    class="align-self-center bank-options"
                    :class="{
                      'is-invalid': formError && formError.status_pernikahan,
                    }"
                    @change="formChange('status_pernikahan')"
                    @select="formChange('status_pernikahan')"
                  />
                </div>

                <div class="form-group">
                  <label> Jenis Kelamin :</label>
                  <div class="row">
                    <div class="col-12 mt-1">
                      <label>
                        <input
                          :disabled="validated == 1 && labelPage == 'Edit'"
                          type="radio"
                          @change="formChange('jenis_kelamin')"
                          v-model="formData.jenis_kelamin"
                          value="Laki - Laki"
                          checked
                        />
                        <div class="d-flex align-items-center">
                          <span style="color: #263238; font-size: 12px"
                            >Laki - Laki</span
                          >
                        </div>
                      </label>
                      <label class="ml-2">
                        <input
                          type="radio"
                          :disabled="validated == 1 && labelPage == 'Edit'"
                          @change="formChange('jenis_kelamin')"
                          v-model="formData.jenis_kelamin"
                          value="Perempuan"
                        />
                        <div class="d-flex align-items-center">
                          <span style="color: #263238; font-size: 12px"
                            >Perempuan</span
                          >
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label> Status :</label>
                  <div class="row">
                    <div class="col-12 mt-1">
                      <label>
                        <input
                          :disabled="validated == 1 && labelPage == 'Edit'"
                          type="radio"
                          v-model="formData.status"
                          @change="formChange('status')"
                          value="aktif"
                          checked
                        />
                        <div class="d-flex align-items-center">
                          <span style="color: #263238; font-size: 12px"
                            >Aktif</span
                          >
                        </div>
                      </label>
                      <label class="ml-2">
                        <input
                          :disabled="validated == 1 && labelPage == 'Edit'"
                          type="radio"
                          v-model="formData.status"
                          @change="formChange('status')"
                          value="tidak"
                        />
                        <div class="d-flex align-items-center">
                          <span style="color: #263238; font-size: 12px"
                            >Nonaktif</span
                          >
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Hubungan Keluarga :</label>
                  <Select2
                    v-model="formData.hubungan_keluarga"
                    :options="optionRelationship"
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    placeholder="Select Hubungan Keluarga"
                    :class="{
                      'is-invalid': formError && formError.hubungan_keluarga,
                    }"
                    @change="formChange('hubungan_keluarga')"
                    @select="formChange('hubungan_keluarga')"
                  />
                </div>

                <div class="form-group">
                  <label>Tanggal Lahir :</label>
                  <Datepicker
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :autoApply="true"
                    v-model="formData.tanggal_lahir"
                    :class="{
                      'is-invalid': formError && formError.tanggal_lahir,
                    }"
                    :maxDate="new Date()"
                    placeholder="Masukkan tanggal lahir"
                    :enableTimePicker="false"
                    :format="format"
                    @update:modelValue="changeDate($event, 'tanggal_lahir')"
                  ></Datepicker>
                </div>
                <div class="form-group">
                  <label>Pekerjaan :</label>
                  <input
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    type="text"
                    v-model="formData.pekerjaan"
                    @keyup="formChange('pekerjaan')"
                    :class="{
                      'is-invalid': formError && formError.pekerjaan,
                    }"
                    class="form-control align-self-center"
                    placeholder="Masukkan Pekerjaan"
                  />
                </div>
                <div class="form-group">
                  <label>Alamat :</label>
                  <textarea
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.alamat"
                    @keyup="formChange('alamat')"
                    :class="{
                      'is-invalid': formError && formError.alamat,
                    }"
                    class="form-control align-self-center"
                    placeholder="Masukkan Alamat"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

// import $ from "jquery";
import { checkRules, showAlert, checkApp } from "../../../helper";
import moment from "moment";
import "moment/locale/id";
import {
  get_KaryawanKeluargaDetail,
  get_KaryawanStatusPernikahan,
  post_KaryawanKeluaragaSave,
} from "../../../actions/karyawan";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  data() {
    return {
      validated: 1,
      dateOut: null,
      enableSync: checkApp("rawuh") ? true : false,
      id_karyawan: this.$route.params.id,
      optionMaritalStatus: [],
      optionRelationship: [{
        id: 'pasangan',
        text: 'Suami/Istri'
      },
      {
        id: 'anak',
        text: 'Anak'
      },
      {
        id: 'lainnya',
        text: 'Lainnya'
      }
      ],
      id_family: this.$route.params.nomor ? this.$route.params.nomor : "",
      labelPage: this.$route.params.nomor ? "Edit" : "Tambah",
      formData: {
        id: this.$route.params.nomor ? this.$route.params.nomor : "",
        id_karyawan: this.$route.params.id,
        nama: "",
        status_pernikahan: "",
        jenis_kelamin: "Laki - Laki",
        status: "aktif",
        tanggal_lahir: "",
        pekerjaan: "",
        alamat: "",
        hubungan_keluarga: "",
        isSync: false,
      },
      rules: {
        nama: {
          required: true,
        },
        status_pernikahan: {
          required: true,
        },
        hubungan_keluarga: {
          required: true,
        },
        jenis_kelamin: {
          required: true,
        },
        status: {
          required: true,
        },
        tanggal_lahir: {
          required: true,
        },
        pekerjaan: {
          required: false,
        },
        alamat: {
          required: false,
        },
      },
      formError: [],
      isSubmit: false,
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },
  created() {
    localStorage.setItem("familyTab", true);
    this.getStatusPernikahan();
    if (this.id_family) {
      this.getData();
    }
  },
  methods: {
    editable() {
      this.validated = 2;
    },
    getData() {
      get_KaryawanKeluargaDetail(
        this.id_family,
        (res) => {
          var { data } = res;
          delete data.created_at;
          delete data.updated_at;
          this.formData = data;
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              localStorage.setItem("familyTab", true);
              this.$router.push({
                name: "DetailEmployee",
                params: {
                  id: this.id_karyawan,
                },
              });
            },
          });
        }
      );
    },
    getStatusPernikahan() {
      get_KaryawanStatusPernikahan((res) => {
        this.optionMaritalStatus = res.list;
      });
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    changeDate(data, key) {
      this.formData[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },

    postData() {
      this.isSubmit = true;
      post_KaryawanKeluaragaSave(
        this.formData,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code == 201
              ? "Data keluarga berhasil dicatat"
              : "Data keluarga berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              localStorage.setItem("familyTab", true);
              this.$router.push({
                name: "DetailEmployee",
                params: {
                  id: this.id_karyawan,
                },
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali nanti",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        this.formData.isSync = false;
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    async onSync() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        this.formData.isSync = true;
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
/* .btn-blue {
  padding: 8px 0px;
  width: 120px;
  font-size: 14px;
} */

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.form-edit {
  width: 100%;
}

.title-detail {
  padding: 30px;
}

.text {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  width: 488px;
  max-width: 236px;
}

.value {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.info-detail {
  padding: 30px;
}

hr {
  margin: 0px;
  padding: 0px;
}

.row {
  /* border: 1px solid #ECEFF1; */
  border-radius: 10px;
}

.name-employee {
  font-size: 18px;
  font-weight: 600;
  margin-top: 1rem;
}

.position {
  color: #c6c6c6;
  font-size: 14px;
  margin-top: 0.5rem;
}

.menu .nav-link {
  color: #000;
}

.menu .nav-link.active {
  background: #68caf8;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px;
}

.menu {
  border: none;
  background: none;
}

.list-menu {
  margin-top: 30px;
}

.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.card {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}
</style>
